import { useCallback } from 'react';
import { StatusTagProps } from '@kk/ui/components/StatusTag';
import { useTranslation } from 'react-i18next';
import { caseApi } from '@/api';
import { useCurrentDepartments } from '@/api/auth/claims';
import { Department } from '@/api/auth/permissions';
import { isCustomerResponse } from '@/api/predicates/caseRecord';

/**
 * The intended use is mapping phase state to StatusTag props for UI purposes.
 * use predicate functions for checking case and phase state against specific conditions.
 */
export function useMapPhaseStatus(): (
  phase?: caseApi.CasePhaseModel,
  loanCase?: caseApi.CaseModel,
) => StatusTagProps | null | undefined {
  const currentDeparments = useCurrentDepartments();
  const { t } = useTranslation();

  return useCallback(
    (
      phase: caseApi.CasePhaseModel | undefined,
      loanCase: caseApi.CaseModel | undefined,
    ): StatusTagProps | null => {
      const title = phase?.phaseStateDescription;
      const executingDepartment = loanCase?.executingOrgUnitCode as
        | Department
        | undefined;

      const customerResponseRecord =
        loanCase?.caseRecords?.find(isCustomerResponse) ?? null;

      const awaitingCustomerResponse =
        loanCase?.casePhaseCode ===
          caseApi.CasePhaseCode.AgreementConfirmation &&
        !customerResponseRecord;

      switch (phase?.phaseStateCode) {
        case caseApi.CasePhaseState.Done:
          return {
            title,
            status: 'success',
          };
        case caseApi.CasePhaseState.Progress:
          if (
            // if the user's current roles are not included in the executing department
            // then the user is awaiting said department instead of it being in progress (indicating that the user is the one executing on the phase)
            executingDepartment &&
            !currentDeparments.includes(executingDepartment)
          ) {
            return {
              title: t('status.waiting', {
                awaiting:
                  // strip everything after underscore in department name
                  executingDepartment.split('_')[0] ?? '',
              }),
              status: 'waiting',
            };
          } else if (awaitingCustomerResponse) {
            return {
              title: t('status.waiting', {
                awaiting: t('company_one'),
              }),
              status: 'waiting',
            };
          }
          return {
            title,
            status: 'in-progress',
          };
        case caseApi.CasePhaseState.NotInitialized:
          return {
            title,
            status: 'pending',
          };
        case caseApi.CasePhaseState.Cancelled:
        case caseApi.CasePhaseState.Rejected:
          return {
            title,
            status: 'fail',
          };
        case caseApi.CasePhaseState.Pending: {
          if (awaitingCustomerResponse) {
            return {
              title: t('status.waiting', {
                awaiting: t('company_one'),
              }),
              status: 'waiting',
            };
          }
          return {
            title,
            status: 'pending',
          };
        }

        default:
          return null;
      }
    },
    [currentDeparments, t],
  );
}

export default useMapPhaseStatus;
