import { isObject } from '@kk/shared/utils/type-guards';
import calculationPageRoute from './CalculationPage/route';
import casePageRoute from './CasePage/route';
import caseRecordPageRoute from './CaseRecordPage/route';
import casesOverviewPageRoute from './CasesOverviewPage/route';
import contractAgreementRoute from './ContractAgreementPage/route';
import contractGenerationRoute from './ContractGenerationPage/route';
import customerRoute from './CustomerPage/route';
import dashboardRoute from './Dashboard/route';
import individualPriceSettingRoute from './IndividualPriceSettingPage/route';
import leasingRoute from './LeasingPage/route';
import loanPageRoute from './LoanPage/route';
import standaloneCalculatorRoute from './StandaloneCalculatorPage/route';
import { ExtendedRouteObject, HandleObject, HandleReturnType } from './types';

export const routes = [
  dashboardRoute,
  customerRoute,
  loanPageRoute,
  casesOverviewPageRoute,
  casePageRoute,
  calculationPageRoute,
  contractGenerationRoute,
  contractAgreementRoute,
  caseRecordPageRoute,
  individualPriceSettingRoute,
  standaloneCalculatorRoute,
  leasingRoute,
] as const;

export type RouteWithChildren = typeof routes extends readonly (infer T)[]
  ? T extends { children: Array<unknown> }
    ? T
    : never
  : never;

export const isRoute = (
  route: unknown,
): route is ExtendedRouteObject<unknown, unknown> =>
  isObject(route) &&
  'path' in route &&
  route.path !== '' &&
  'id' in route &&
  route.id !== '';

export const isRouteWithChildren = (
  route: unknown,
): route is RouteWithChildren => isRoute(route) && 'children' in route;

export const hasHandle = (
  route: unknown,
): route is {
  handle: (handleObject: HandleObject<unknown, unknown>) => HandleReturnType;
} => isRoute(route) && 'handle' in route && typeof route.handle === 'function';

const routesWithChildren = routes.filter(isRouteWithChildren);

/** for testing purposes */
const childRoutesWithFullPathsAndParentLoader = routesWithChildren.flatMap(
  (route) => {
    return route.children
      .filter((child) => child.path !== '')
      .map((child) => ({
        ...child,
        path: `${route.path}/${child.path}` as const,
        loader: route.loader, // inherit loader from parent
      }));
  },
);

export const allPaths = [
  ...routes.map((route) => route.path).filter(Boolean),
  ...childRoutesWithFullPathsAndParentLoader.map((route) => route.path),
] as const;

export const allRoutes = [
  ...routes,
  ...childRoutesWithFullPathsAndParentLoader,
] as const;

export const allRouteIds = allRoutes.map((route) => route.id);

export type Routes = (typeof allRoutes)[number];
export type PathWithPlaceholders = (typeof allPaths)[number];
export type RouteId = (typeof allRouteIds)[number];
export type RouteFromRouteId<Id extends RouteId> = Id extends RouteId
  ? Extract<(typeof allRoutes)[number], { id: Id }>
  : never;
export type LoaderDataFromRouteId<Id extends RouteId> = Id extends RouteId
  ? Awaited<ReturnType<RouteFromRouteId<Id>['loader']>>
  : never;

export default routes;
